import { v4 } from "uuid";

import {
  doc,
  collection,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
  updateDoc,
  QueryConstraint,
  OrderByDirection,
  orderBy,
  deleteDoc,
} from "@spesill/libs/firebase";
import { EvaluateDatabase } from "@spesill/models";

import { DBRepositoryBase } from "./__common__/DBRepositoryBase";

export class EvaluateDatabaseRepository extends DBRepositoryBase<
  ExcludeMethods<EvaluateDatabase>
> {
  private PATH = "evaluateDatabases";

  add = async (
    document: Omit<
      ExcludeMethods<EvaluateDatabase>,
      "id" | "createdAt" | "updatedAt"
    >,
  ) => {
    const docId = v4();
    const now = new Date();
    const newPage = {
      ...document,
      createdAt: now,
      updatedAt: now,
      id: undefined,
      deletedAt: undefined,
    }; // idをobjectに詰めるとdocIdとは別にidというフィールドができてしまうためundefinedに

    await setDoc(doc(this.db, this.PATH, docId), this.objectToDoc(newPage));

    return new EvaluateDatabase({ ...newPage, id: docId });
  };

  findByTenantIdAndId = async (tenantId: string, id: string) => {
    const q = query(
      collection(this.db, this.PATH),
      where("tenantId", "==", tenantId),
      where("__name__", "==", id),
    );
    const docs = await getDocs(q).then((res) => res.docs);

    if (!docs[0]) {
      throw new Error("評価データベースが見つかりませんでした");
    }
    return new EvaluateDatabase(this.docToObject(docs[0]));
  };

  findByTenantId = async (
    tenantId: string,
    filter?: {
      createUserId?: string;
    },
    sort?: {
      field: keyof EvaluateDatabase;
      order: OrderByDirection;
    },
  ) => {
    const queryConstraints: QueryConstraint[] = [
      where("tenantId", "==", tenantId),
    ];
    if (filter?.createUserId) {
      queryConstraints.push(where("createUserId", "==", filter.createUserId));
    }
    if (sort) {
      queryConstraints.push(orderBy(sort.field, sort.order));
    }
    const q = query(collection(this.db, this.PATH), ...queryConstraints);
    const docs = await getDocs(q).then((res) => res.docs);

    if (!docs) {
      throw new Error("評価データベースが見つかりませんでした");
    }

    return docs.map((doc) => new EvaluateDatabase(this.docToObject(doc)));
  };

  updateById = async (
    id: string,
    database: Omit<
      ExcludeMethods<EvaluateDatabase>,
      "id" | "createdAt" | "updatedAt"
    >,
  ) => {
    const ref = doc(this.db, this.PATH, id);
    const documentData = await getDoc(ref);

    if (!documentData.exists()) {
      throw new Error("評価データベースが見つかりませんでした");
    } else {
      await updateDoc(ref, {
        ...this.objectToDoc(database),
        id: undefined, // idをobjectに詰めるとdocIdとは別にidというフィールドができてしまうためundefinedに
        updatedAt: new Date(),
      });
    }
    return { id };
  };

  // 論理削除
  logicalDeleteById = async (id: string) => {
    const ref = doc(this.db, this.PATH, id);
    const document = await getDoc(ref);

    if (!document.exists()) {
      throw new Error("評価データベースが見つかりませんでした");
    } else {
      await updateDoc(ref, { deletedAt: new Date() });
    }
  };

  restoreById = async (id: string) => {
    const ref = doc(this.db, this.PATH, id);
    const document = await getDoc(ref);

    if (!document.exists() || !document.data()?.deletedAt) {
      throw new Error("評価データベースが見つかりませんでした");
    } else {
      await updateDoc(ref, { deletedAt: null });
    }
  };

  deleteById = async (id: string) => {
    const ref = doc(this.db, this.PATH, id);
    const document = await getDoc(ref);

    if (!document.exists() || !document.data()?.deletedAt) {
      throw new Error("評価データベースが見つかりませんでした");
    } else {
      await deleteDoc(ref);
    }
  };
}
