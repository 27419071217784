import { Entity } from "./__common__/entity";

export type DocumentPermissionType = "all" | "specific";
export type DocumentUserPermission = "viewer" | "editor" | "none";

export class TenantDocumentSettings extends Entity {
  id: string;
  tenantId: string;
  customPrompt: string;
  customEvaluationPrompt?: string;
  tenantName?: string;
  businessDetails?: string;

  constructor(arg: ExcludeMethods<TenantDocumentSettings>) {
    super(arg);

    this.id = arg.id;
    this.tenantId = arg.tenantId;
    this.customPrompt = arg.customPrompt;
    this.customEvaluationPrompt = arg.customEvaluationPrompt;
    this.tenantName = arg.tenantName;
    this.businessDetails = arg.businessDetails;
  }

  static async create(
    args: Omit<ConstructorParameters<typeof TenantDocumentSettings>[0], "id">,
  ) {
    return new TenantDocumentSettings({
      id: args.tenantId,
      ...args,
    });
  }
}
